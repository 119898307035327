<template>
    <b-modal id="room-multiple-unavailablility-dates-modal" hide-footer scrollable size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
      <template #modal-title>
        <h2 class="m-0">Make Rooms Unavailable</h2>
      </template>
      <template v-if="isBusy">
        <div class="text-center text-danger my-2">
          <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-else>
          <b-form @submit.prevent>
            <b-row>
              <b-col>
                <b-form-group label-for="city">
                    <template #label>
                    City
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="city" inputId="id" label="name" v-model="selectedCity"
                            :options="cities" placeholder="City" class="v-style-chooser"/>
                        </b-col>
                    </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <template v-if="selectedCity">
            <b-row>
              <b-col>
                <b-form-group label-for="mess">
                    <template #label>
                    Mess
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="mess" inputId="id" label="name" v-model="selectedMess"
                            :options="messes" placeholder="Mess" class="v-style-chooser"/>
                        </b-col>
                    </b-row>
                </b-form-group>
              </b-col>
            </b-row>
        </template>
            <!-- <template v-if="selectedMess">
            <b-row>
              <b-col>
                <b-form-group label-for="roomtype">
                    <template #label>
                    Room Type <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>   
                            <v-select id="roomtype" inputId="id" label="name" v-model="selectedRoomType"
                            :options="roomTypes" placeholder="Room Type" class="v-style-chooser"/>
                        </b-col>
                    </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            </template> -->
            <!-- <template v-if="selectedMess && selectedRoomType"> -->
            <template v-if="selectedMess">
              <b-row>
                  <b-col md="12" sm="12" lg="12" xl="12">
                      <b-form-group
                        label-for="rooms"
                      >
                        <label>
                          Rooms
                        </label>
                        <b-row>
                          <b-col>
                              <v-select
                                inputId="id"
                                label="displayName"
                                v-model="selectedRooms"
                                :options="filteredRooms"
                                multiple
                                autoscroll
                              ></v-select>
                              <b-overlay :show="showSpinner" spinner-variant="primary" no-wrap>
                                <span>Loading...</span>
                              </b-overlay>
                          </b-col>
                        </b-row>
                      </b-form-group>
                  </b-col>
              </b-row>
            </template>
            <template v-if="selectedRooms">
                <b-row>
                    <b-col md="6" sm="6" lg="6" xl="6">
                        <b-form-group label-for="unavailableFrom">
                          <span>
                            From Date:
                          </span>
                          <b-input-group>
                            <b-form-input
                            id="unavailableFrom"
                            v-model="unavailableFrom"
                            type="text"
                            placeholder="YYYY-MM-DD"
                            autocomplete="on"
                            disabled
                            ></b-form-input>
                            <b-input-group-append>
                            <b-form-datepicker
                                id="unavailableFromDatePicker"
                                v-model="unavailableFrom"
                                button-variant="primary"
                                :min="minFromDate"
                                button-only
                                right
                                variant="primary"
                            />
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="6" lg="6" xl="6">
                        <b-form-group label-for="unavailableTo">
                            <span>
                              To Date:
                            </span>
                            <b-input-group>
                                <b-form-input
                                id="unavailableTo"
                                v-model="unavailableTo"
                                type="text"
                                placeholder="YYYY-MM-DD"
                                autocomplete="on"
                                disabled
                                ></b-form-input>
                                <b-input-group-append>
                                <b-form-datepicker
                                    id="unavailableToDatePicker"
                                    v-model="unavailableTo"
                                    button-variant="primary"
                                    :min="minToDate"
                                    button-only
                                    right
                                    variant="primary"
                                />
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
            </template>
            <b-form-group class="text-right">
                <b-button variant="primary" size="md" class="mr-1 mt-2" @click="addUnavailabilityDates">
                    Confirm
                </b-button>
            </b-form-group>
          </b-form>
      </template>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  
  export default {
    components: {
    },
    mixins: [util],
    props: ["showModal"],
    data() {
      return {
        showSpinner: false,
        show:false,
        messes: [],
        selectedMess: null,
        cities: [],
        selectedCity: null,
        rooms: [],
        filteredRooms: [],
        selectedRooms: [],
        isBusy: false,
        currentDate: null,
        minFromDateObj: null,
        minFromDate: null,
        minToDateObj: null,
        minToDate: null,
        unavailableFrom: null,
        unavailableTo: null,
      };
    },
    async mounted() {
      if (this.showModal) {
        try {
            this.isBusy = true
            this.currentDate = await this.getCurrentDate()
            this.minFromDateObj = new Date(this.currentDate.getTime())
            this.minFromDate = await this.getPakistanDateString(this.currentDate);
            // this.minToDateObj = new Date(this.currentDate.getTime())
            // this.minToDate = await this.getPakistanDateString(this.currentDate);
            await this.setUnavailableFromDate(this.minFromDate)
            let res = await this.getBookingCitiesUnpaginated({
                mess_only: true,
            });
            this.cities = res.data
            // let res= await this.getMessesUnpaginated({})
            // this.messes = res.data
            //--------------------------------- city changes 
            // res = await this.getRoomsUnpaginated()
            // this.rooms = res.data
            // this.filteredRooms = this.rooms

            this.isBusy = false
        } catch (error) {
          this.displayError(error);
        }
      }
    },
    methods: {
      ...mapActions({
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getRoomsUnpaginated: "appData/getRoomsUnpaginated",
        getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
        fetchSystemTime: "appData/fetchSystemTime",   
        createRoomUnavailabilityDate: "appData/createRoomUnavailabilityDate",     
      }),
      async setUnavailableFromDate(dateString){
        this.unavailableFrom = dateString
      },
      async setUnavailableToDate(dateString){
        this.unavailableTo = dateString
      },
      async getCurrentDate() {
        await this.fetchSystemTime()
        var systemDateTime  = await this.getSystemTime;
        var currentDate = new Date(systemDateTime);
        return currentDate
      },
      async getPakistanDateString(date){
        date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
        var day = date.split("/")[1]
        var month = date.split("/")[0]
        var year = date.split("/")[2]
        return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
      },
      async updateMess()
      {
        try{
          this.selectedMess = null
          let res = await this.getMessesUnpaginated({city: this.selectedCity.id})
          this.messes = res.data
        }
        catch(error)
        {
          this.displayError
        }
      },
      async addUnavailabilityDates(){
            try {
                let selectedRoomsList = []
                for(let i=0; i<this.selectedRooms.length; i++){
                  selectedRoomsList.push(this.selectedRooms[i].id)
                }
                let dataToInsert = {
                    multiple: true,
                    rooms: selectedRoomsList,
                    unavailable_from: this.unavailableFrom,
                    unavailable_to: this.unavailableTo,
                };
                
                const res = await this.createRoomUnavailabilityDate(dataToInsert);
                if (res.status === 201) {
                    this.$swal({
                    title: "Room unavailability dates added successfully",
                    icon: "success",
                    });
                    this.$nextTick(() => {
                        this.$bvModal.hide("room-multiple-unavailablility-dates-modal");
                        this.$emit("modalClosed");
                    });
                }
            }
            catch (error) {
                if (error.response.status == 500) {
                  this.$swal({
                    title: "Room(s) already unavailable for one or more dates in the given range",
                    icon: "error",
                  });
                  this.$nextTick(() => {
                    this.$bvModal.hide("room-multiple-unavailablility-dates-modal");
                    this.$emit("modalClosed");
                  });
                }
            }
      },
      async refreshAvailableRooms() {
        try {
          this.showSpinner = true
          let tempParams = {
            mess: this.selectedMess.id
          };
          let res = await this.getRoomsUnpaginated(tempParams);
          this.rooms = res.data;
          if(this.selectedMess){
            this.filteredRooms = this.rooms.map(room => ({
              ...room,
              displayName: `${room.name} (${room.room_type_data.name})  ${ !(room.starting_category_name.toLowerCase().includes('none')) ? ` (${room.starting_category_name})` : ''}  ${ !(room.starting_lien_name.toLowerCase().includes('none')) ? ` (${room.starting_lien_name})` : ''}`
            }));
          }
          this.showSpinner = false
        } catch (error) {
          console.error('Error refreshing available rooms:', error);
          this.showSpinner = false
        }
      },
     
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ 
          getLoggedInUser: "appData/getUser",
          getGlobalPoliciesState: "appData/getGlobalPoliciesState",
          getSystemTime: "appData/getSystemTime",          
        }),
    },
    watch: {
     async selectedCity(newValue, oldValue)
      {
         try {
          if (newValue) {
            this.messes = []
            await this.updateMess();
          } else {
            return
          }
        } catch (error) {
          console.error('Error in selectedMess watch:', error);
        }
      },
      async selectedMess(newValue, oldValue) {
        try {
          if (newValue) {
            await this.refreshAvailableRooms();
            this.selectedRooms = this.selectedRooms.filter(room => room.mess === newValue.id);
            this.selectedRooms = [];
          } else {
            this.filteredRooms = this.rooms;
            this.selectedRooms = [];
          }
        } catch (error) {
          console.error('Error in selectedMess watch:', error);
        }
      },
      async unavailableFrom(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;
            var unavailableFromDate = new Date(formattedDateString);
            this.minToDateObj = new Date(unavailableFromDate.getTime())
            this.minToDate = await this.getPakistanDateString(this.minToDateObj);
            // await setUnavailableToDate(this.minToDate)
            this.unavailableTo = this.minToDate
          }
        } catch (error) {

        }
      },
      async unavailableTo(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;       
            this.minToDateObj = new Date(formattedDateString);
          }
        } catch (error) {

        }
      },
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  .alert-overlay-text-size {
    font-size: 30px;
  }
  </style>
  