<template>
    <div>
        <b-form @submit.prevent>
          <b-row>
            <b-col cols="4" class="ml-3">
                <b-form-group label-for="city">
                  <template #label>
                    City
                  </template>
                  <b-row>
                    <b-col>
                      <v-select id="city" inputId="id" label="name" v-model="selectedCity" :options="cities"
                        placeholder="City" class="v-style-chooser" />
                    </b-col>
                  </b-row>
                </b-form-group>
            </b-col>
            <b-col cols="4" v-if="selectedCity">
              <b-form-group label-for="mess">
                <template #label>
                  Mess
                </template>
                <b-row>
                  <b-col>
                    <v-select id="mess" inputId="id" label="name" v-model="selectedMess" :options="messes"
                      placeholder="Mess" class="v-style-chooser" />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="3" v-if="selectedMess">
                <b-form-group label-for="mess">
                <template #label>
                  Room
                </template>
                <b-row>
                  <b-col>
                    <v-select id="room" inputId="id" label="name" v-model="selectedRoom" :options="rooms"
                      placeholder="Room" class="v-style-chooser" />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mx-5 mt-1" v-if="selectedMess">
              <b-col cols="5">
                  <b-form-group>
                      <span>
                        From Date:
                      </span>
                      <b-input-group>
                          <b-form-input
                          id="unavailableFromFilter"
                          v-model="unavailableFromFilter"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                          ></b-form-input>
                          <b-input-group-append>
                          <b-form-datepicker
                              id="unavailableFromFilterDatePicker"
                              v-model="unavailableFromFilter"
                              button-variant="primary"
                              :min="minFromDateFilter"
                              button-only
                              right
                              variant="primary"
                          />
                          </b-input-group-append>
                      </b-input-group>
                  </b-form-group>
              </b-col>
              <b-col cols="5">
                  <b-form-group>
                      <span>
                        To Date:
                      </span>
                      <b-input-group>
                          <b-form-input
                          id="unavailableToFilter"
                          v-model="unavailableToFilter"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          autocomplete="on"
                          disabled
                          ></b-form-input>
                          <b-input-group-append>
                          <b-form-datepicker
                              id="unavailableToFilterDatePicker"
                              v-model="unavailableToFilter"
                              button-variant="primary"
                              :min="minToDateFilter"
                              button-only
                              right
                              variant="primary"
                          />
                          </b-input-group-append>
                      </b-input-group>
                  </b-form-group>
              </b-col>
              <b-col cols="2" class="mt-2">
                <b-button variant="primary" @click="resetDateFilters">
                    <span class="align-middle">Clear Dates</span>
                </b-button>
              </b-col>
            </b-row>
        </b-form>
      <div class="mt-2">
        <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1">
            <template v-if="selectedMess">
              <div class="
                        d-flex
                        justify-content-center
                        align-items-center
                        flex-nowrap
                        mr-5
                        ">
                    <b-button variant="primary" pill @click="filter">
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                    </b-button>
                </div>
            </template>
        </div>
        <div class="d-flex justify-content-center align-items-center flex-nowrap mb-1 mt-2">
          <template v-if="selectedRoom">
              <div class="
                      d-flex
                      justify-content-center
                      align-items-center
                      flex-nowrap
                      mr-5
                      ">
                      <b-button variant="primary" pill @click="openUnavailabilityDateModal"
                      v-if="hasPermission('room_unavailability_date_create')">
                      <span class="align-middle">Make selected room unavailable for booking</span>
                      </b-button>
              </div>
          </template>
          <div class="
                    d-flex
                    justify-content-center
                    align-items-center
                    flex-nowrap
                    mr-5
                    ">
                    <b-button variant="primary" pill @click="openMultipleUnavailabilityDateModal"
                    v-if="hasPermission('room_unavailability_date_create')">
                    <span class="align-middle">Make multiple rooms unavailable for booking</span>
                    </b-button>
            </div>          
        </div>
      </div>
      <b-card class="mt-5">
        <b-table responsive :fields="fields" :items="roomUnavailabilityDates"  details-td-class="m-0 p-0" small
          v-if="hasPermission('room_unavailability_date_show')" :per-page="0" :busy="isBusy">
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner :variant="'primary'" class="align-middle"></b-spinner>
            </div>
          </template>
          <template #cell(manage)="row">
            <div class="d-flex align-items-center">
                <template>
                    <b-button variant="danger" pill size="sm" class="mr-1" @click="deleteUnavailabilityDate(row.item)"
                        v-if="hasPermission('room_unavailability_date_delete')">
                        Delete
                    </b-button>
                </template>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col md="2">
            <div style="float:left">
              <h5 style="margin: 0; display: inline;" class="text-primary">Count: </h5>
              <h5 style="margin: 0; display: inline;" align="right">
                <strong>
                  {{ totalDataLength }}
                </strong>
              </h5>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-center align-items-center flex-nowrap m-2">
          <b-pagination size="md" :total-rows="totalItems" v-model="currentPage" :per-page="perPage"></b-pagination>
        </div>
      </b-card>
      <b-modal
        id="room-unavailablility-dates-modal"
        title="Make Room Unavailable"
        @modalClosed="onModalClosed"
        @hidden="makeUnavailableHidden"
        :showModal="visible"
        :no-close-on-esc="true"
        :no-close-on-backdrop="true"
        hide-footer
        :key="`unavailable-${roomUnavailableDateModalCount}`"
        centered
        >
            <validation-observer ref="roomUnavailableDatesModalValidation">
                <b-form @submit.prevent>
                    <b-row>
                    <b-col md="6" sm="6" lg="6" xl="6">
                        <b-form-group label-for="unavailableFrom">
                          <span>
                            From Date:
                          </span>
                            <validation-provider
                            #default="{ errors }"
                            name="unavailableFromField"
                            :rules="{ required: true }"
                            >
                            <b-input-group>
                                <b-form-input
                                id="unavailableFrom"
                                v-model="unavailableFrom"
                                type="text"
                                placeholder="YYYY-MM-DD"
                                autocomplete="on"
                                disabled
                                ></b-form-input>
                                <b-input-group-append>
                                <b-form-datepicker
                                    id="unavailableFromDatePicker"
                                    v-model="unavailableFrom"
                                    button-variant="primary"
                                    :min="minFromDate"
                                    button-only
                                    right
                                    variant="primary"
                                />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col md="6" sm="6" lg="6" xl="6">
                        <b-form-group label-for="unavailableTo">
                            <span>
                              To Date:
                            </span>
                            <validation-provider
                            #default="{ errors }"
                            name="unavailableToField"
                            :rules="{ required: true }"
                            >
                            <b-input-group>
                                <b-form-input
                                id="unavailableTo"
                                v-model="unavailableTo"
                                type="text"
                                placeholder="YYYY-MM-DD"
                                autocomplete="on"
                                disabled
                                ></b-form-input>
                                <b-input-group-append>
                                <b-form-datepicker
                                    id="unavailableToDatePicker"
                                    v-model="unavailableTo"
                                    button-variant="primary"
                                    :min="minToDate"
                                    button-only
                                    right
                                    variant="primary"
                                />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    </b-row>
                    <b-row>
                    <b-button variant="primary" size="sm" class="mr-1 ml-auto mt-1 p-75 px-2" @click="validateUnavailabilityDates">
                        Confirm
                    </b-button>              
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
        <b-overlay :show="show" spinner-variant="primary" no-wrap />
          <RoomUnavailabilityModalMultiple
            @modalClosed="onModalClosed"
            :showModal="visible"
            :key="`multiple-${roomMultipleUnavailableDateModalCount}`"
          />
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import moment from "moment";
  import RoomUnavailabilityModalMultiple from "@/components/booking/admin/RoomUnavailabilityModalMultiple.vue";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      RoomUnavailabilityModalMultiple
    },
  
    data() {
      return {
        fields: [
          { key: "manage", label: "Manage" },
          { key: "room_data.name", label: "Room Name" },
          { key: "room_data.type", label: "Room Type" },
          { key: "room_data.starting_category", label: "Room Category" },
          { key: "room_data.starting_lien", label: "Room Lien" },
          { key: "date", label: "Date" },
        ],
        currentPage: 1,
        perPage: 0,
        totalItems: 0,
        totalDataLength: 0,
        messSecretary: false,
        messReceptionist: false,
        selectedRoom: null,
        rooms: [],
        selectedMess: null,
        messes: [],
        selectedMessId: null,
        cities: [],
        selectedCity: null,
        show: false,
        roomUnavailabilityDates: [],
        isBusy: false,
        minFromDateObj: null,
        minFromDate: null,
        minToDateObj: null,
        minToDate: null,
        unavailableFrom: null,
        unavailableTo: null,
        roomUnavailableDateModalCount: 0,
        visible: false,
        minFromDateFilterObj: null,
        minFromDateFilter: null,
        minToDateFilterObj: null,
        minToDateFilter: null,
        unavailableFromFilter: null,
        unavailableToFilter: null,
        currentDate: null,
        roomMultipleUnavailableDateModalCount: 0,
      };
    },
    mixins: [util],
    async mounted() {
      this.currentDate = await this.getCurrentDate()
      this.minFromDateObj = new Date(this.currentDate.getTime())
      this.minFromDate = await this.getPakistanDateString(this.currentDate);
      // this.minToDateObj = new Date(this.currentDate.getTime())
      // this.minToDate = await this.getPakistanDateString(this.currentDate);
      await this.setUnavailableFromDate(this.minFromDate)
      this.minFromDateFilterObj = new Date(this.currentDate.getTime())
      this.minFromDateFilter = await this.getPakistanDateString(this.currentDate);
      this.minToDateFilterObj = new Date(this.currentDate.getTime())
      this.minToDateFilter = await this.getPakistanDateString(this.currentDate);
      // await this.setUnavailableFromFilterDate(this.minFromDateFilter)
      let res = await this.getBookingCitiesUnpaginated({
        mess_only: true,
      });
      this.cities = res.data
    },
    methods: {
      ...mapActions({
        fetchSystemTime: "appData/fetchSystemTime",
        getMessesUnpaginated: "appData/getMessesUnpaginated",
        getRoomsUnpaginated: "appData/getRoomsUnpaginated",
        getRoomsAvailabilityReport: "appData/getRoomsAvailabilityReport",
        getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
        roomsAvailabilityReportFromExcel: "appData/roomsAvailabilityReportFromExcel",
        getRoomUnavailabilityDates: "appData/getRoomUnavailabilityDates",
        createRoomUnavailabilityDate: "appData/createRoomUnavailabilityDate",
        delete: "appData/deleteRoomUnavailabilityDate",
      }),
      async resetDateFilters(){
        this.unavailableFromFilter = null
        this.unavailableToFilter = null
        this.minFromDateFilterObj = new Date(this.currentDate.getTime())
        this.minFromDateFilter = await this.getPakistanDateString(this.currentDate);
        this.minToDateFilterObj = new Date(this.currentDate.getTime())
        this.minToDateFilter = await this.getPakistanDateString(this.currentDate);
      },
      async setUnavailableFromDate(dateString){
        this.unavailableFrom = dateString
      },
      async setUnavailableFromFilterDate(dateString){
        this.unavailableFromFilter = dateString
      },
      async setUnavailableToDate(dateString){
        this.unavailableTo = dateString
      },
      async setUnavailableToFilterDate(dateString){
        this.unavailableToFilter = dateString
      },
      async getCurrentDate() {
        await this.fetchSystemTime()
        var systemDateTime  = await this.getSystemTime;
        var currentDate = new Date(systemDateTime);
        return currentDate
      },
      async getPakistanDateString(date){
        date = date.toLocaleString("en-US", {timeZone: "Asia/Karachi"}).split(",")[0];
        var day = date.split("/")[1]
        var month = date.split("/")[0]
        var year = date.split("/")[2]
        return year + '-' + month.padStart(2, '0') + '-' + day.padStart(2, '0');
      },
      async onModalClosed() {
        await this.search();
        this.visible = false;
      },
      makeUnavailableHidden(){
        this.unavailableFrom= this.minDate;
        this.unavailableTo= null
      },
      openUnavailabilityDateModal(){
        this.roomUnavailableDateModalCount += 1;
        this.visible = true;
        this.$nextTick(() => {
            this.$bvModal.show("room-unavailablility-dates-modal");
        });
      },
      openMultipleUnavailabilityDateModal(){
        this.roomMultipleUnavailableDateModalCount += 1;
        this.visible = true;
        this.$nextTick(() => {
            this.$bvModal.show("room-multiple-unavailablility-dates-modal");
        });
      },
      async validateUnavailabilityDates(){
        const success = await this.$refs.roomUnavailableDatesModalValidation.validate();
        if (success) {
            await this.addUnavailabilityDates();
        }
      },
      async addUnavailabilityDates(){
            try {
                let dataToInsert = {
                    room: this.selectedRoom.id,
                    unavailable_from: this.unavailableFrom,
                    unavailable_to: this.unavailableTo,
                };
                const res = await this.createRoomUnavailabilityDate(dataToInsert);
                if (res.status === 201) {
                    this.$swal({
                    title: "Room unavailability dates added successfully",
                    icon: "success",
                    });
                    this.$nextTick(() => {
                        this.filter()
                        this.$bvModal.hide("room-unavailablility-dates-modal");
                    });
                }
            }
            catch (error) {
                if (error.response.status == 500) {
                  this.$swal({
                    title: "Room is already unavailable for one or more dates in the given range",
                    icon: "error",
                  });
                  this.$nextTick(() => {
                    this.filter()
                    this.$bvModal.hide("room-unavailablility-dates-modal");
                  });
                }
            }
      },
      async updateMess() {
        try {
          this.selectedMess = null
          let res = await this.getMessesUnpaginated({ city: this.selectedCity.id })
          this.messes = res.data
        }
        catch (error) {
          this.displayError
        }
      },
      async updateRooms() {
        try {
          this.selectedRoom = null
          let res = await this.getRoomsUnpaginated({ mess: this.selectedMess.id })
          this.rooms = res.data
          this.rooms = this.rooms.map(room => ({
              ...room,
              name: `${room.name} (${room.room_type_data.name})  ${ !(room.starting_category_name.toLowerCase().includes('none')) ? ` (${room.starting_category_name})` : ''}  ${ !(room.starting_lien_name.toLowerCase().includes('none')) ? ` (${room.starting_lien_name})` : ''}`
            }));
        }
        catch (error) {
          this.displayError
        }
      },
      async filter() {
        this.currentPage = 1;
        await this.search();
      },
      async search() {
        this.isBusy = true;
        await this.fetchPaginatedData();
        this.isBusy = false;
      },
      async fetchPaginatedData(){
        try {
            let tempParams = {
              pageNumber: this.currentPage,
            };
            if(this.selectedRoom){
              tempParams['room'] = this.selectedRoom.id
            }
            else if(this.selectedMess){
              tempParams['mess'] = this.selectedMess.id
            }
            if(unavailableFromFilter){
              tempParams['date_from'] = this.unavailableFromFilter 
            }
            if(unavailableToFilter){
              tempParams['date_to'] = this.unavailableToFilter 
            }
            let res = await this.getRoomUnavailabilityDates(tempParams)
            this.roomUnavailabilityDates = res.data.results
            this.totalItems = res.data.count
            this.perPage = res.data.per_page
            this.totalDataLength = res.data.count
        }
        catch (error) {
        //   this.displayError
        }        
      },
      async deleteUnavailabilityDate(roomUnavailabilityDate) {
        try {
            this.$swal({
            title:
                "Are you sure?",
            icon: "warning",
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: "Confirm",
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-danger ml-1",
            },
            buttonsStyling: false,
            }).then(async (result) => {
            if (result.value) {
                try {
                const res = await this.delete({
                    pk: roomUnavailabilityDate.id,
                });
                if (res.status === 204) {
                    this.$swal({
                    title: "Successfully deleted",
                    icon: "success",
                    });
                    this.$nextTick(() => {
                    this.search();
                    });
                }
                } catch (error) {
                this.show = false;
                }
            } else {
                this.show = false;
            }
            });
        } catch (error) {
            // this.displayError(error);
        }
        },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
        getLoggedInUser: "appData/getUser",
        getSystemTime: "appData/getSystemTime",
      }),
    },
    watch: {
      async selectedCity(newValue, oldValue) {
        try {
          if (newValue) {
            this.messes = []
            this.selectedMess = null
            this.rooms = []
            this.selectedRoom = null
            this.roomUnavailabilityDates = []
            this.totalItems = 0
            this.perPage = 0
            this.totalDataLength = 0
            await this.updateMess();
          } else {
            return
          }
        } catch (error) {
          console.error('Error in selectedCity watch:', error);
        }
      },
      async selectedMess(newValue, oldValue) {
        try {
          if (newValue) {
            this.rooms = []
            this.selectedRoom = null
            this.roomUnavailabilityDates = []
            this.totalItems = 0
            this.perPage = 0
            this.totalDataLength = 0
            await this.updateRooms();
          } else {
            return
          }
        } catch (error) {
          console.error('Error in selectedMess watch:', error);
        }
      },
      async selectedRoom(newValue, oldValue) {
        try {
          if (newValue) {
            this.roomUnavailabilityDates = []
            this.totalItems = 0
            this.perPage = 0
            this.totalDataLength = 0            
            // await this.search();
          }
        } catch (error) {

        }
      },
      async unavailableFrom(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;
            var unavailableFromDate = new Date(formattedDateString);
            this.minToDateObj = new Date(unavailableFromDate.getTime())
            this.minToDate = await this.getPakistanDateString(this.minToDateObj);
            // await setUnavailableToDate(this.minToDate)
            this.unavailableTo = this.minToDate
          }
        } catch (error) {

        }
      },
      async unavailableTo(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;       
            this.minToDateObj = new Date(formattedDateString);
          }
        } catch (error) {

        }
      },
      async unavailableFromFilter(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;
            var unavailableFromDate = new Date(formattedDateString);
            this.minToDateFilterObj = new Date(unavailableFromDate.getTime())
            this.minToDateFilter = await this.getPakistanDateString(this.minToDateFilterObj);
            this.unavailableToFilter = this.minToDateFilter
          }
        } catch (error) {

        }
      },
      async unavailableToFilter(newValue, oldValue){
        try {
          if (newValue) {
            let dateStringISO8601 = newValue + ' 00:00:00 +05:00'
            let parts = dateStringISO8601.split(' ');
            let datePart = parts[0]; // '2024-07-05'
            let timePart = parts[1]; // '00:00:00'
            let offsetPart = parts[2]; // '+05:00'
            let formattedDateString = datePart + 'T' + timePart + offsetPart;       
            this.minToDateFilterObj = new Date(formattedDateString);
          }
        } catch (error) {

        }
      },
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
  
  <style scoped>
  </style>